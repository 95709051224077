<template>
  <time :datetime="time.datetime" ref="element">
    <slot name="before" :time="time" />
    <span v-if="format !== 'custom'">{{ formatted }}</span>
    <slot :time="time" />
    <slot name="after" :time="time" />
  </time>
</template>

<script setup>
import { watch, ref, onBeforeUnmount, computed } from 'vue'
import { useElementVisibility } from '@vueuse/core'
import {
  formatPast,
  formatDate,
  formatTime,
  formatDateShort,
  formatFuture,
  formatFutureTime
} from '@/components/ui/time/formats'

defineComponent({
  name: 'UiTime'
})

const { $time } = useNuxtApp()

const element = ref(null)
const interval = ref(null)
const time = ref(null)
const formatted = ref('')

const $emit = defineEmits(['timeout'])
const $props = defineProps({
  value: {
    type: [String, Number],
    default: ''
  },
  /**
   * Formats:
   * future-time - 00:10:26
   */
  format: {
    type: String,
    default: 'date',
    validate: v =>
      [
        'custom',
        'past',
        'date',
        'date-short',
        'time',
        'future',
        'datetime',
        'future-time'
      ].includes(v)
  },
  clock: {
    type: Boolean,
    default: false
  },
  timeout: {
    type: [Number, String],
    default: 60000
  },
  toMs: {
    type: Boolean,
    default: false
  }
})

const timestamp = computed(() =>
  $props.toMs ? $props.value * 1000 : $props.value
)

const init = () => {
  time.value = $time(timestamp.value)
  calc()
  if ($props.clock && elementIsVisible.value) {
    startClock()
  }
}
const startClock = () => {
  if (interval.value) return
  interval.value = setInterval(calc, $props.timeout)
}
const stopClock = () => {
  clearInterval(interval.value)
  interval.value = null
}
const calc = () => {
  time.value.update()
  formatted.value = getFormatted()
  checkFuture()
}
const checkFuture = () => time.value.left > 0 && $emit('timeout')
const getFormatted = () => {
  if ($props.format === 'past') {
    return formatPast(time.value)
  } else if ($props.format === 'date') {
    return formatDate(time.value)
  } else if ($props.format === 'date-short') {
    return formatDateShort(time.value)
  } else if ($props.format === 'datetime') {
    return formatDate(time.value) + ' ' + formatTime(time.value)
  } else if ($props.format === 'time') {
    return formatTime(time.value)
  } else if ($props.format === 'future') {
    return formatFuture(time.value)
  } else if ($props.format === 'future-time') {
    return formatFutureTime(time.value)
  } else {
    return 'there is no such format'
  }
}

const elementIsVisible = useElementVisibility(element)

watch(elementIsVisible, val => onVisible(val))

const onVisible = visible => {
  if (!visible && $props.clock) stopClock()
  if (visible) init()
}

onBeforeUnmount(() => {
  clearInterval(interval.value)
})

watch(() => $props.value, init)
init()
</script>
