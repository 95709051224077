export const formatPast = time => {
  if (time.past.days.value > 30) {
    return formatDate(time)
  } else if (time.past.days.value) {
    return `${time.past.days.value} ${time.t.relative.days.left} ${time.t.ago}`
  } else if (time.past.hours.value) {
    const value = time.past.hours.value > 1 ? time.past.hours.value + ' ' : ''
    return `${value}${time.t.relative.hours.left} ${time.t.ago}`
  } else if (time.past.minutes.value) {
    const value =
      time.past.minutes.value > 1 ? time.past.minutes.value + ' ' : ''
    return `${value}${time.t.relative.minutes.left} ${time.t.ago}`
  } else if (time.past.seconds.value >= 20) {
    return `${time.past.seconds.value} ${time.t.relative.seconds.left} ${time.t.ago}`
  } else {
    return `${time.t.rightnow}`
  }
}

export const formatDate = time => {
  const now = new Date()
  return `${time.date.day.string} ${time.t.month} ${
    now.getFullYear() !== time.date.year.value ? time.date.year.string : ''
  }`
}

export const formatDateShort = time => {
  const now = new Date()
  return `${time.date.day.string} ${time.t.month.substr(0, 3)} ${
    now.getFullYear() !== time.date.year.value ? time.date.year.string : ''
  }`
}

export const formatTime = time => {
  return `${time.date.hours.string}:${time.date.minutes.string}`
}

export const formatFuture = time => {
  if (time.future.days.value) {
    return `${time.future.days.value} ${time.t.relative.days.left}`
  } else {
    return formatFutureTime(time)
  }
}

export const formatFutureTime = time => {
  let result = ''
  if (time.future.days.value) {
    result += `${time.future.days.string}:`
  }
  result += `${time.future.hours.string}:${time.future.minutes.string}:${time.future.seconds.string}`
  return result
}
